import { $fetch } from 'ofetch'
import type { FetchOptions } from 'ofetch'
import LRU from 'lru-cache'
import { hash as ohash } from 'ohash'

const cache = new LRU({
  max: 500,
  ttl: 2000 * 60 * 60, // 2 hour
})

function appguide<T = AppGuide.ApiResponse>(endpoint: string, arg?: FetchOptions): Promise<T> {
  const token = useCookie('token')
  const config = useRuntimeConfig()

  return $fetch(endpoint, {
    ...arg,
    baseURL: config.public.api,
    onRequest({ options }) {
      if (!token.value)
        return

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token.value}`,
      }
    },
  })
}

type ApiParams = Record<string, string | number | undefined>

function _fetchAppGuide<T = any>(url: string, params: ApiParams = {}): Promise<T> {
  return appguide(url, {
    params,
  })
}

function parseParams(params: Record<string, any>): ApiParams {
  return Object.entries(params).reduce((acc, [key, value]) => {
    let parsedValue = value

    if (Array.isArray(value))
      parsedValue = value.join(',')

    return {
      ...acc,
      [key]: parsedValue,
    }
  }, {})
}

export function fetchAppGuide<T = any>(url: string, params: ApiParams = {}): Promise<T> {
  const hash = ohash([url, params])
  if (!cache.has(hash)) {
    cache.set(
      hash,
      _fetchAppGuide<T>(url, params)
        .catch((e) => {
          cache.delete(hash)
          throw e
        }),
    )
  }
  return cache.get(hash)! as any
}

// Applications
interface SearchResponse {
  applications: AppGuide.Application[]
  meta: {
    total: number
    limit: number
    currentPage: number
    totalPages: number
  }
}
export async function searchApplications(params: AppGuide.ListApplicationsParams): Promise<SearchResponse> {
  return _fetchAppGuide<AppGuide.Application>('/applications', parseParams(params))
    .then((res: any) => ({
      applications: res.data.items,
      meta: {
        total: res.data.meta.totalItems,
        limit: res.data.meta.itemsPerPage,
        currentPage: res.data.meta.currentPage,
        totalPages: res.data.meta.totalPages,
      },
    }))
}

export async function getApplicationFull(uid: string): Promise<AppGuide.ApplicationFull> {
  return fetchAppGuide<AppGuide.Application>(`/applications/${uid}/full`)
    .then((res: any) => res.data)
}

// Auth
export async function getLogout() {
  return appguide('/auth/logout')
}

// Shares
export async function sendShare(share: {
  applicationId: string | number
  sent_by_sms?: 1
  sent_by_email?: 1
  phone?: string
  email?: string
  language: string
  description: string
  display_name: string
}) {
  return appguide('/shares/add', {
    method: 'POST',
    body: share,
  })
}

export async function getShare(code: string) {
  return appguide(`/shares/getby/url/${code}`)
}

export async function sendShareAnalytics(code: string, body: { open_page?: 1; open_store?: 1 }) {
  return appguide(`/shares/analytics/${code}`, {
    method: 'POST',
    body,
  })
}

export async function getLibrary(id: number | string) {
  return appguide<AppGuide.Libraries.GetLibrariesResponse>(`/libraries/user/get/${id}`)
}

export async function getPost(slug: string, language: 'en' | 'fr') {
  return appguide<AppGuide.Quill.GetPostBySlugResponse>(`/articles/posts/getby/${slug}/${language}`)
}

export async function getPostList(params: { language: AppGuide.locales; page: number; limit: number; condition?: string; search?: string }) {
  return appguide<AppGuide.Quill.GetPostListResponse>('/articles/posts/all', { params: parseParams(params) })
}

export async function getSummary(id: number | string) {
  return appguide<AppGuide.Summaries.GetSummaryResponse>(`/summaries/public/get/${id}`)
}

export async function listSummaries(params: { limit: number; page: number; conditions?: string }) {
  return appguide<AppGuide.Summaries.ListSummariesResponse>('/summaries/public/all', { params: parseParams(params) })
}

export async function postSummaryNewsletter(body: { email: string; summaryId: number; language: string }) {
  return appguide('/summaries/newsletter/push', { method: 'POST', body })
}

export async function pushCollectionAppAnalytics(id: number | string, body: {
  action: 'open-page' | 'open-store'
  utm_source?: string
}) {
  return appguide(`/libraries/collections/applications/analytics/push/${id}`, { method: 'PUT', body })
}
